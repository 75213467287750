<template>
    <div style="margin-bottom:2rem;">
        <div class="title1">
            <p class="title_top">ONLINE MESSAGE</p>
            <p class="title_content blue">在线留言</p>
        </div>
        <div>
            <Info></Info>
        </div>
    </div>
</template>
<script>
import Info from "@/components/Info.vue";
export default {
    components:{
        Info,
    },
    data(){
        return{

        }
    }
}
</script>